<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a
        @click.prevent="toggleNavBar"
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        ref="burguer"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu" ref="navBar">
      <div class="navbar-start">
        <span class="navbar-item">
          <img src="..\assets\img\image843.png"  />
        </span>

        <router-link class="navbar-item" :to="{ name: 'view' }">
          Campanyes
        </router-link>

        <router-link class="navbar-item" :to="{ name: 'viewShops' }">
          Comerços
        </router-link>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> Crear </a>

          <div class="navbar-dropdown">
            <a class="navbar-item"
              ><router-link :to="{ name: 'create' }">
                Nova Campanya
              </router-link>
            </a>
            <a class="navbar-item"
              ><router-link :to="{ name: 'createShop' }">
                Nou Usuari / Comerç
              </router-link></a
            >
            <!-- <hr class="navbar-divider" />
            <a class="navbar-item"> Report an issue </a> -->
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <template v-if="user">
              <!-- <router-link class="button is-primary" :to="{ name: 'profile' }">
                <strong>Profile</strong>
              </router-link> -->

              <a @click="doLogout" class="button is-white">
                Desconnecta &nbsp;
                <i class="fa fa-power-off" aria-hidden="true"></i>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NavBarComponent",
  mounted() {
    this.burguer = this.$refs.burguer;
    this.navBar = this.$refs.navBar;
  },
  data() {
    return {
      burguer: null,
      navBar: null,
    };
  },
  methods: {
    toggleNavBar() {
      this.burguer.classList.toggle("is-active");
      this.navBar.classList.toggle("is-active");
    },
    async doLogout() {
      try {
        await this.$store.dispatch("user/doLogout");
        this.$router.push({ name: "auth" });
        this.$toast.success("logged out");
      } catch (error) {
        this.$toast.error(error.message);
        console.error(error.message);
      }
    },
  },
  computed: {
    ...mapState("user", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.navbar-item img {
  max-height: none;
}
.navbar__logo {
  width: 50px;
  height: 50px;
}
a {
  text-decoration: none;
}
</style>