<template>
  <div id="app">
    <div id="borde" class="field m-4">
      <nav-bar-component />
    </div>

    <router-view />
    <br /><br /><br />
    <div class="columns is-mobile is-multiline is-centered">
      <div class="column is-narrow">
        <img src=".\assets\img\logo_conjunto.png"  width="850" height="850"/>
      </div>
    </div>
  <br /> <br />
    <h6 class="subtitle is-6 is-pulled-right m-5">
      Powered by

      <a href="www.senianet.com">Sènia Informàtica</a>
    </h6>
  </div>
</template>

<script>
import NavBarComponent from "./components/NavBarComponent.vue";

export default {
  name: "App",
  components: {
    NavBarComponent,
  },
};
</script>

<style scoped>
#borde {
  border-style: solid;
  border-color: darkgrey;
  border-width: 0px 0px 1px 0px; /* 25px top, 10px right, 4px bottom and 35px left */
  padding: 5px;
}
</style>


