// import Vue from 'vue';
import Vuex from 'vuex';

import messages from './messages';
import rooms from './rooms';
import shops from './shops';
import user from './user';
import utils from './utils';
import { auth } from "../firebase.js";

const store = new Vuex.Store({
  state: {
    isloading : true
  },
  getters: {
  },
  mutations: {
    setLoading(state, loading) {
      state.isloading = loading
    }
  },
  actions: {
    checkAuth({ dispatch, commit}) {
      auth.onAuthStateChanged(function(user){
        if (user) {
          commit("user/setUser", user);
          dispatch("rooms/getRooms");
          dispatch("shops/getShops");
        }else{
          commit("user/setUser", null);
          commit("rooms/setRooms", []);
          commit("shops/setShops", []);
          commit("rooms/setRoomsListener", () => {});
          commit("shops/setShopsListener", () => {});
        }
      });
    }
  },
  modules: {
    messages,
    rooms,
    user,
    utils, 
    shops
  }
});

export default store;

//Initial load
store.dispatch("checkAuth");

