import { db } from "../firebase";
import { collection, deleteDoc, updateDoc, doc, addDoc, orderBy, query, onSnapshot } from "firebase/firestore"


const state = {
    shops: [],
    shopsListener: () => {}
};

const getters = {
    getShop: state => id => {
        return state.shops.find(shop => shop.id === id)
    }
};

const mutations = {
    setShopsListener(state, listener) {
        if (listener) {
            state.shopsListener = listener;  
        } else {
            state.shopsListener();
        }
    },
    setShops(state, shops) {
        state.shops = shops
    },
    createShop(state, {shopData, id}) {
        shopData.id = id;
        state.shops.unshift(shopData);
    },
    updateShop(state, {index, shopData, id}) {
        shopData.id = id;
        state.shops[index] = shopData;
    },
    removeShop(state,index) {
        state.shops.splice(index, 1);
    }
};

const actions = {
    async createShop(context, { name, usuari, contrasenya }) {
        await addDoc(collection(db, "shops"), {
            name,
            usuari,
            contrasenya,
            createdAt: Date.now(),
        });
    },
    async getShops({ commit }) {
        const shopsRef = collection(db, "shops");
        const q = query(shopsRef, orderBy("createdAt", "desc"));
        commit("setShopsListener", q);
        onSnapshot(q, (querySnapshot) => {
            commit("setLoading", true, { root: true});
            querySnapshot.docChanges().forEach(change =>{
                if (change.type === "added"){
                    commit("createShop",{
                        shopData: change.doc.data(),
                        id: change.doc.id
                    });
                }
                if (change.type === "modified"){
                    commit("updateShop",{
                        index: change.newIndex,
                        shopData: change.doc.data(),
                        id: change.doc.id
                    });
                } 
                if (change.type === "removed"){
                    commit("removeShop",change.oldIndex);
                }
            });
            commit("setLoading", false, { root: true});
        });

    },

    async getShop(context, shopID) {

        return await doc(db, "shops", shopID);
    },

    async updateShop(context, { shopID, name, usuari, contrasenya}) {
        const shopData = {};
        if (name) shopData.name = name;
        if (usuari) shopData.usuari = usuari;
        if (contrasenya) shopData.contrasenya = contrasenya;
        await updateDoc(doc(db, "shops", shopID), shopData);

    },
    async removeShop(context, shopID) {
        const shop = doc(db, "shops", shopID);
        await deleteDoc(shop);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};