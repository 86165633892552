
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// import { getFirestore } from "firebase/firestore"
// import { getStorage } from "firebase/storage";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
//import "firebase/compat/firestore";
// import "firebase/compat/storage";


const firebaseConfig = {
  apiKey: "AIzaSyCyeOrLtQ1JFZtPun0DAbXN8Q9Z9vOvmas",
  authDomain: "campanyesacsa.firebaseapp.com",
  projectId: "campanyesacsa",
  storageBucket: "campanyesacsa.appspot.com",
  messagingSenderId: "53261112259",
  appId: "1:53261112259:web:0d65f576f866de4f91a376",
  measurementId: "G-BLQ7D43DNJ"
};

// Initialize Firebase
export const fb = initializeApp(firebaseConfig);
export const db = getFirestore(fb);
export const auth = getAuth(fb);
// export const storage = getStorage(fb);
