import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

require("./assets/css/main.scss");

// import {fb} from "./firebase";
// import { getAuth } from "firebase/auth";
// console.log(getAuth(fb));

createApp(App).use(store).use(router).use(VueToast, {
    position: "bottom"
}).mount('#app')
