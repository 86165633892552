import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store';
const CreateRoom = () => import ("../views/CreateRoom.vue");
const CreateShop = () => import ("../views/CreateShop.vue");
const UserProfileView = () => import ("../views/UserProfileView.vue");
const RoomsView = () => import ("../views/RoomsView.vue");
const ShopsView = () => import ("../views/ShopsView.vue");
const AuthView = () => import ("../views/AuthView.vue");
const UpdateRoom = () => import ("../views/UpdateRoom.vue");
const UpdateShop = () => import ("../views/UpdateShop.vue");
const TableRoom = () => import ("../views/TableRoom.vue");

const routes = [
  {
    path: '/',
    name: 'home',
    component: RoomsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthView
  },
  {
    path: "/profile",
    name: "profile",
    component: UserProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/create",
    name: "create",
    component: CreateRoom,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: "/createShop",
    name: "createShop",
    component: CreateShop,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: "/view",
    name: "view",
    component: RoomsView,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: "/viewShops",
    name: "viewShops",
    component: ShopsView,
    meta: {
      requiresAuth: true
    }

  },
  {
    path: "/update/:id",
    name: "update",
    props: true,
    component: UpdateRoom,
    meta: {
      requiresAuth: true
    }
  },
    {
      path: "/updateShop/:id",
      name: "updateShop",
      props: true,
      component: UpdateShop,
      meta: {
        requiresAuth: true
      }
  },
  {
    path: "/tableRoom/:id",
    name: "tableRoom",
    props: true,
    component: TableRoom,
    meta: {
      requiresAuth: true
    }
}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to,from,next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !(await store.dispatch("user/getCurrentUser"))){
    next({name: "auth" });
  } else if (!requiresAuth && (await store.dispatch("user/getCurrentUser"))){
    next({name: "home" });
  } else {
    // anything
    next(); 
  }
});

export default router
