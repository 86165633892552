import { db } from "../firebase";
import { collection, deleteDoc, updateDoc, doc, addDoc, orderBy, query, onSnapshot } from "firebase/firestore"


const state = {
    rooms: [],
    roomsListener: () => {}
};

const getters = {
    getRoom: state => id => {
        return state.rooms.find(room => room.id === id)
    }
};

const mutations = {
    setRoomsListener(state, listener) {
        if (listener) {
            state.roomsListener = listener;  
        } else {
            state.roomsListener();
        }
    },
    setRooms(state, rooms) {
        state.rooms = rooms
    },
    createRoom(state, {roomData, id}) {
        roomData.id = id;
        state.rooms.unshift(roomData);
    },
    updateRoom(state, {index, roomData, id}) {
        roomData.id = id;
        state.rooms[index] = roomData;
    },
    removeRoom(state,index) {
        state.rooms.splice(index, 1);
    }
};

const actions = {
    async createRoom(context, { name}) {
        await addDoc(collection(db, "rooms"), {
            name,
            createdAt: Date.now(),
            finished: false,
            ticketShops: {},
            checkedShops: [],
            ticketTable: [],
        });
    },
    async getRooms({ commit }) {
        const roomsRef = collection(db, "rooms");
        const q = query(roomsRef, orderBy("createdAt", "desc"));
        commit("setRoomsListener", q);
        onSnapshot(q, (querySnapshot) => {
            commit("setLoading", true, { root: true});
            querySnapshot.docChanges().forEach(change =>{
                if (change.type === "added"){
                    commit("createRoom",{
                        roomData: change.doc.data(),
                        id: change.doc.id
                    });
                }
                if (change.type === "modified"){
                    commit("updateRoom",{
                        index: change.newIndex,
                        roomData: change.doc.data(),
                        id: change.doc.id
                    });
                } 
                if (change.type === "removed"){
                    commit("removeRoom",change.oldIndex);
                }
            });
            commit("setLoading", false, { root: true});
        });
    },

    async getRoom(context, roomID) {
        return await doc(db, "rooms", roomID);
    },

    async updateRoom(context, { roomID, name, checkedShops, ticketShops }) {
        const roomData = {};
        if (name) roomData.name = name;
        if (ticketShops) roomData.ticketShops = ticketShops;
        if (checkedShops) roomData.checkedShops = checkedShops;
        await updateDoc(doc(db, "rooms", roomID), roomData);
    },
    async pausePlayRoom(context, { roomID, finished}) {
        if (finished){
            await updateDoc(doc(db, "rooms", roomID), {finished : false});
        } else {
            await updateDoc(doc(db, "rooms", roomID), {finished : true});
        }    
    },
    async removeRoom(context, roomID) {
        const room = doc(db, "rooms", roomID);

        await deleteDoc(room);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};