
import { auth } from "../firebase.js";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, 
         signOut, updateProfile, updateEmail, updatePassword, sendPasswordResetEmail } from "firebase/auth";

const state = {
    user: null
};

const getters = {
    getUserUid(state) {
        return state.user.uid;
    }
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    }
};

const actions = {
    getCurrentUser() {
        return new Promise((resolve, reject) => {
            const unsubscribe = auth.onAuthStateChanged(
                user => {
                    unsubscribe();
                    resolve(user);
                },
                () => {
                    reject();
                }
            );
        });
    },
    async updateProfile({ commit }, { name, email, password }) {
        const user = auth.currentUser;

        if (name) {
            await updateProfile( user, {
                displayName: name
            });  
        }

        if (email) {
            await updateEmail(user, email);
        }

        if (password) {
            await updatePassword(user, password);
        }

        commit("setUser", user);
    },
    async doLogin({ commit }, { email, password }) {
        await signInWithEmailAndPassword(auth, email, password);
        commit("setUser", auth.currentUser);
    },
    async doRegister({ commit }, { name, email, password }) {
        await createUserWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;
        await updateProfile(user, {
            displayName: name
        });
        commit("setUser", user);
    },
    async doLogout({ commit }) {
        await signOut(auth);
        commit("setUser", null);
    },
    async doReset(context, {email}){
        console.log(email);
        await sendPasswordResetEmail(auth, email);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};